@use "mixins";
@use "variables";

.scrollblock {
  overflow: hidden;
}

::-ms-reveal {
  display: none;
  opacity: 0;
}

body {
  background-color: mixins.theme-var(variables.$color-background-var);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: top;
  position: absolute;
  width: 100%;
  margin: 0;
}

svg {
  text-rendering: optimizeSpeed
}

html {
  height: 100%;
  touch-action: auto;
  font-size: variables.$font-size-default; /* root font size */
  font-family: variables.$font-default;
  color: mixins.theme-var(variables.$color-primary-var);
  background-color: mixins.theme-var(variables.$color-background-var);
  @include mixins.scrollbar();

  &.no-scroll {
    overflow: hidden;
    height: 100%;
    width: 100%;
    box-sizing: border-box;

    &-ios {
      position: fixed;

      body {
        position: absolute;
        width: 100%;
      }
    }
  }
}

.container {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 30px;
  box-sizing: border-box;

  @include mixins.media-below(variables.$bp-sm) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.input-container {

  .btn {
    width: 100%;
  }

  & > app-form-input {
    width: 100%;
    margin-bottom: 15px;
  }
}

.input {
  outline: none;
  cursor: pointer;
  background: transparent;
  color: mixins.theme-var(variables.$color-text-var);
  border-radius: 0;
  box-sizing: border-box;
  height: 40px;
  border: 0;
  border-bottom: 2px solid transparent;
  width: 100%;

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: mixins.theme-var(variables.$color-divider-var);
  }

  &--simple {
    @include mixins.font(16px, 700);
    transition: 0.2s;
    padding: 14px 16px 0 16px;
    border-radius: 42px;

    &:focus {
      & + .form-control__label {
        @include mixins.font(10px, 600);
        top: 5px;
      }
    }
  }

  &--outline {
    border: 2px solid mixins.theme-var(variables.$color-primary-var);
    //color: $color-text-light;
    border-radius: 50px;
  }
}

textarea.input--simple {
  padding-top: 10px;
}


.form-control {

  &--container {
    display: flex;
    justify-content: space-between;

    app-form-input {
      width: 100%;

      &:not(:last-child) {
        margin-right: 13px;
      }
    }
  }
}

.checkbox {
  position: relative;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  min-height: 26px;

  &--primary {
    .checkbox__view {
      &:after {
        width: 7px;
        height: 7px;
        background-color: mixins.theme-var(variables.$color-primary-var);
        border: none;
        display: block;
        box-sizing: border-box;
        border-radius: 1px;
        position: absolute;
        content: '';
        transform: none;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
      }
    }
  }

  &--secondary {
    .checkbox__view {
      @include mixins.gradientBorder(5px);
      position: relative;
      width: 17px;
      height: 17px;
      border-radius: 5px;
      box-sizing: border-box;
      margin-right: 14px;
      flex-shrink: 0;
      background: unset;
      transform: scale(1.15);
      margin-top: -3px;
    }

    .checkbox__label {
      @include mixins.font(12px, 600, 'Inter');
      color: mixins.theme-var(variables.$color-text-var);
      padding-left: 0;
      margin-top: -3px;
      cursor: default;
    }

    input[type='checkbox'] {
      &:not(:checked).ng-touched.ng-invalid + .checkbox__view {
        @include mixins.gradientBorder(5px, 3px, mixins.theme-var(variables.$input-border-gradient-error-var));
      }
    }

    input[type='checkbox'] {
      &:checked + .checkbox__view {
        position: relative;

        &:after {
          position: absolute;
          left: 3px;
          top: 3px;
          content: '\2713';
          display: flex;
          font-size: 17px;
          font-weight: 800;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &--toggle {
    input[type='checkbox'] {
      &:checked + .checkbox__view {
        background-image: linear-gradient(mixins.theme-var(variables.$color-badge-var), mixins.theme-var(variables.$color-badge-var)), mixins.theme-var(variables.$input-border-gradient-var);

        &:after {
          display: block !important;
          transform: translateX(20px);
          background-color: mixins.theme-var(variables.$color-primary-var);
        }
      }
    }

    .checkbox__view {
      width: 46px;
      height: 26px;
      background-image: linear-gradient(mixins.theme-var(variables.$color-background-var), mixins.theme-var(variables.$color-background-var)), mixins.theme-var(variables.$color-lang-gradient-var);
      border-radius: 40px;

      &:after {
        width: 16px;
        height: 16px;
        border-radius: 20px;
        background-color: mixins.theme-var(variables.$color-primary-var);
        margin-top: 3px;
        margin-left: 3px;
        border: 0;
        transform: none;
        content: '';
        display: block !important;
        transition: 0.2s transform cubic-bezier(0.6, 0.4, 0.1, 0.9);
      }
    }

    .checkbox__label {
      @include mixins.font(14px, 400, 'Inter');
      padding-left: 60px;
      color: mixins.theme-var(variables.$color-text-var);
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    display: none;

    &:not(:checked) {
      & + .checkbox__view {
        &:after {
          display: none;
        }
      }
    }
  }

  &__view {
    position: absolute;
    box-sizing: border-box;
    cursor: pointer;
    width: 16px;
    height: 16px;
    border: solid 2px transparent;
    border-radius: 2px;
    background-image: linear-gradient(mixins.theme-var(variables.$color-menu-var), mixins.theme-var(variables.$color-menu-var)), mixins.theme-var(variables.$input-border-gradient-var);
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }

  &__label {
    @include mixins.font(12px, 400, Cunia);
    display: block;
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    color: mixins.theme-var(variables.$color-text-var);
    user-select: none;
    line-height: 1.5;

    @include mixins.media-below(variables.$bp-sm) {
      padding-left: 35px;
    }
  }
}

.radio {
  display: flex;
  justify-content: center;
  align-items: center;
  @include mixins.font(14px, 500);
  cursor: pointer;
  position: relative;
  overflow: hidden;

  input {
    position: absolute;
    left: -9999px;

    &:checked + .radio__view {
      &:before {
        background-color: mixins.theme-var(variables.$color-primary-var);
        box-shadow: inset 0 0 0 0.205em mixins.theme-var(variables.$color-radio-border-var);
      }
    }

    &:disabled + .radio__view {
      opacity: 0.5;
      pointer-events: none;
      cursor: none;
    }

    &:disabled + .radio__view + .radio__label {
      pointer-events: none;
      cursor: none;
    }

    @include mixins.ngError {
      & + .radio__view {
        &:before {
          box-shadow: inset 0 0 0 0.205em mixins.theme-var(variables.$color-error-var);
        }
      }
    }
  }

  &__view {
    display: flex;
    align-items: center;
    padding: 0.375em 0.75em 0.375em 0.375em;
    border-radius: 99em;
    transition: 0.25s ease;

    &:before {
      display: flex;
      flex-shrink: 0;
      content: "";
      width: 1.5em;
      height: 1.5em;
      border-radius: 50%;
      transition: 0.25s ease;
      background-color: inherit;
      box-shadow: inset 0 0 0 0.205em mixins.theme-var(variables.$color-radio-border-var);
    }
  }

  &__label {
    color: mixins.theme-var(variables.$color-text-var);
  }
}

.select {
  @include mixins.font(14px, 500);
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
  color: #000;

  @include mixins.ngError {
    .select__header {
      border-color: mixins.theme-var(variables.$color-error-var);
    }
  }

  &__header {
    @include mixins.font(13px, 700);
    display: block;
    height: 46px;
    padding: 14px 10px 12px 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: mixins.theme-var(variables.$color-background-var);
    color: mixins.theme-var(variables.$color-text-var);
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    border: 3px solid mixins.theme-var(variables.$color-divider-var);
    border-radius: 13px;
    box-shadow: 0px 4px 0px 0px mixins.theme-var(variables.$input-shadow-color-var);

    &.gradient {
      border: unset;
      background: inherit;
      box-shadow: 0 6px 0 0 mixins.theme-var(variables.$input-shadow-color-var);
      border-radius: 18px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        border-radius: 16px;
        padding: 3px;
        background: mixins.theme-var(variables.$input-border-gradient-var);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: destination-out;
        mask-composite: exclude;
        z-index: 1;
      }
    }

    &[disabled] {
      cursor: unset;

      > *, &:after {
        opacity: 0.5;
        pointer-events: none;
        user-select: none;
      }
    }

    &:after {
      display: block;
      position: absolute;
      content: '';
      width: 5px;
      height: 5px;
      border: 2px solid mixins.theme-var(variables.$color-primary-var);
      border-top-width: 0;
      border-left-width: 0;
      transform: translateY(-50%) rotateZ(45deg);
      right: 20px;
      top: calc(50% - 2px);
    }

  }

  &--secondary {
    .select {
      &__header {
        @include mixins.font(12px, 500);
        background-color: transparent;
        border: 4px solid mixins.theme-var(variables.$color-primary-var);
        text-transform: uppercase;
        // color: $color-text-light;
        border-radius: 40px;

        &:after {
          border-color: mixins.theme-var(variables.$color-secondary-var);
          top: 14px;
          width: 7px;
          height: 7px;
        }
      }
    }

    &.big {
      .select {
        &__header {
          color: #fff;
          height: 60px;
          line-height: 54px;
          padding: 0 75px 0 35px;
          border-radius: 60px;

          &:after {
            top: 23px;
            right: 26px;
          }
        }
      }
    }
  }

  &__dropdown {
    position: absolute;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%) scale(0.9, 0);
    width: 100%;
    transition: transform 0.2s, opacity 0.2s;
    opacity: 0.5;
    padding: 5px 0;
    background-color: mixins.theme-var(variables.$color-background-var);;
    overflow: hidden;
    transform-origin: center;
    box-sizing: border-box;
    border: 3px solid mixins.theme-var(variables.$color-divider-var);
    border-radius: 13px;
    box-shadow: 0px 4px 0px 0px mixins.theme-var(variables.$input-shadow-color-var);
    z-index: 10001;

    &-container {
      overflow: auto;
      max-height: 180px;
      @include mixins.scrollbar();

      &::-webkit-scrollbar {
        background-color: #fff;
      }
    }

    &.open {
      visibility: visible;
      transform: translateY(-50%) scale(1, 1);
      opacity: 1;
      pointer-events: all;
    }
  }

  &__option {
    @include mixins.font(10px, 600);
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    box-sizing: border-box;
    padding: 8px 15px;
    transition: 0.1s;
    outline: none;
    color: mixins.theme-var(variables.$color-text-var);
    text-transform: capitalize;

    &:hover {
      background-image: mixins.theme-var(variables.$input-border-gradient-var);
    }
  }

}

.text {
  h1 {
    font-size: 18px;
    margin: 0;
  }
}

.link {
  color: mixins.theme-var(variables.$color-primary-var);
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.btn {
  @include mixins.font(14px, 700, Cunia);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-sizing: border-box;
  height: 40px;
  cursor: pointer;
  border: 0;
  color: mixins.theme-var(variables.$color-background-var);
  transition: 0.1s;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 10px;
  position: relative;
  text-align: center;
  min-width: 140px;
  padding: 0;

  &:disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &:active {
    background-color: mixins.theme-var(variables.$color-text-var);
  }

  &::before {
    content: '';
    position: absolute;
    left: 3px;
    top: calc(50% + 2px);
    transform: translateY(-50%);
    border-left: 15px solid rgba(255, 255, 255, 0.4);
    border-bottom: 3px solid transparent;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    right: 3px;
    top: calc(50% + 2px);
    transform: translateY(-50%);
    border-right: 15px solid rgba(255, 255, 255, 0.4);
    border-bottom: 3px solid transparent;
    z-index: 1;
  }

  &.big {
    width: 209px;
  }

  .content {
    @include mixins.flex(row, center, center);
    position: relative;
    height: calc(100% - 6px);
    width: 100%;
    padding: 0 15px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: calc(50% - 1px);
      transform: translateY(-50%);
      border-left: 15px solid rgba(0, 0, 0, 0.4);
      border-top: 3px solid transparent;
      z-index: 1;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: calc(50% - 1px);
      transform: translateY(-50%);
      border-right: 15px solid rgba(0, 0, 0, 0.4);
      border-top: 3px solid transparent;
      z-index: 1;
    }
  }

  &--filled-primary {
    background: mixins.theme-var(variables.$color-gradient-primary-second-var);
    filter: drop-shadow(mixins.theme-var(variables.$color-primary-shadow-var) 0 3px);

    .content {
      background: mixins.theme-var(variables.$color-gradient-primary-var);
      margin: 3px;
      border-radius: 10px;
      box-shadow: inset 10px 0 20px rgba(255, 255, 255, 0.5);
      position: relative;
    }

    @include mixins.media-above(variables.$bp-sm) {
      &:hover {
        background-color: mixins.theme-var(variables.$color-secondary-var);
      }
    }

    &:active {
      background-color: mixins.theme-var(variables.$color-primary-var);
    }

    &.small {
      min-width: 120px;

      .content {
        @include mixins.font(14px, 700, 'Cunia');
        margin: 3px;
      }
    }

    &.big {
      height: 50px;
      min-width: 260px;

      .content {
        @include mixins.font(16px, 700, 'Cunia');
      }
    }
  }

  &--filled-secondary {
    background: mixins.theme-var(variables.$color-gradient-secondary-second-var);
    filter: drop-shadow(mixins.theme-var(variables.$color-secondary-shadow-var) 0 3px);

    .content {
      background: mixins.theme-var(variables.$color-gradient-secondary-var);
      border-radius: 10px;
      box-shadow: inset 5px 0 5px rgba(255, 255, 255, 0.25);
      position: relative;
    }

    @include mixins.media-above(variables.$bp-sm) {
      &:hover {
        background-color: mixins.theme-var(variables.$color-primary-var);
      }
    }

    &.small {
      min-width: 120px;

      .content {
        @include mixins.font(14px, 700, 'Cunia');
        margin: 3px;
      }
    }

    &.big {
      height: 50px;
      min-width: 260px;

      .content {
        @include mixins.font(16px, 700, 'Cunia');
      }
    }
  }
}

.informer {
  &__tooltip {
    position: absolute;
    padding: 15px;
    display: none;
    z-index: 1000;
    background-color: mixins.theme-var(variables.$color-primary-var);
    color: mixins.theme-var(variables.$color-menu-var);
    border-radius: 10px;
    @include mixins.font(14px);

    &.visible {
      display: block;
    }
  }
}
