@use "sass:math";
@use "sass:map";
@use "variables";

@mixin font($size, $weight: null, $family: null) {
  font-size: calc($size / variables.$font-size-default * 1rem);
  @if $weight != null {
    font-weight: $weight
  }
  @if $family != null {
    font-family: $family
  }
}

@mixin autofill() {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    @content;
  }
}

@mixin stroke($width, $color) {
  $width: $width + 0;
  $shadow: 0 0 0 transparent;
  $i: 0;
  $w: 1;

  @while ($i < $width) {
    $i: $i + 1;
    $j: 0;
    $w: $w + 2;

    @for $r from 1 through $w {
      @for $c from 1 through $w {
        $x: $c - calc($w / 2);
        $y: $r - calc($w / 2);

        $shadow: #{$shadow}, #{$x}px #{$y}px 0 $color;
      }
    }
  }

  text-shadow: $shadow;
}

@mixin ngError() {
  &.ng-invalid.ng-touched {
    @content;
  }
}

@mixin gradientBorder($border-radius: 16px, $border-thickness: 3px, $gradient: (theme-var(variables.$input-border-gradient-var))) {
  position: relative;
  border-radius: $border-radius;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    padding: $border-thickness;
    background: $gradient;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    z-index: 1;
  }

  input {
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    padding: 10px;
    border: none;
    outline: none;
    position: relative;
    z-index: 2;
    background: unset !important;
  }
}

@mixin borderWithShadow(
  $border-radius: 13px,
  $border-width: 7px,
  $border-height: 14px,
  $padding: 7px,
  $gradient: theme-var(variables.$color-background-social-var),
  $inner-shadow: theme-var(variables.$input-inner-shadow-color-var),
  $outer-shadow: theme-var(variables.$input-shadow-color-var)
) {
  position: relative;
  border-radius: $border-radius;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - #{$border-width});
    height: calc(100% - #{$border-height});
    border-radius: $border-radius;
    padding: $padding;
    background: $gradient;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    box-shadow: 2.64px 0 4.62px 0 $inner-shadow inset, 0 2.64px 0 0 $outer-shadow;
    z-index: 1;
  }

  input {
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    padding: 10px;
    border: none;
    outline: none;
    position: relative;
    z-index: 2;
    background: unset !important;
  }
}

@mixin shadow3D($shadow-color:theme-var(variables.$input-shadow-color-var), $shadow-offset: 6px, $bottom-padding: -5px) {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    display: flex;
    bottom: $bottom-padding;
    left: 1px;
    right: -5px;
    box-shadow: 0 $shadow-offset 0 0 $shadow-color;
    z-index: 0;
    height: 27px;
    border-radius: 16px;
  }
}

@mixin gradientBorderRound($block-bg:theme-var(variables.$color-menu-var), $gradient:theme-var(variables.$color-lang-gradient-var), $border-width: 3px) {
  background: linear-gradient($block-bg, $block-bg) padding-box, $gradient border-box;
  border: $border-width solid transparent;
}

@mixin scrollbar($colorScrollbar:theme-var(variables.$color-background-var), $colorThumb:theme-var(variables.$color-primary-var)) {
  -webkit-overflow-scrolling: touch;

  @-moz-document url-prefix() {
    scrollbar-width: thin;
    scrollbar-color: $colorScrollbar $colorScrollbar;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 2px;
    background-color: $colorScrollbar;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 8px;
    background-color: $colorThumb;
  }
}


@mixin hideScrollBar() {
  -ms-overflow-style: none; // IE 10+
  overflow: -moz-scrollbars-none; // Firefox old
  scrollbar-width: none; /* Firefox new */
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin retina {
  @media only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (min--moz-device-pixel-ratio: 2),
  only screen and (-o-min-device-pixel-ratio: 2/1),
  only screen and (min-device-pixel-ratio: 2),
  only screen and (min-resolution: 192dpi),
  only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin media-between($lower, $upper) {
  @media (min-width: $lower) and (max-width: ($upper - 1)) {
    @content;
  }
}

@mixin media-below($breakpoint) {
  @media (max-width: ($breakpoint - 1)) {
    @content;
  }
}

@mixin media-above($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

@mixin media-height-between($lower, $upper) {
  @media (min-height: $lower) and (max-height: ($upper - 1)) {
    @content;
  }
}

@mixin media-height-below($breakpoint) {
  @media (max-height: ($breakpoint - 1)) {
    @content;
  }
}

@mixin media-height-above($breakpoint) {
  @media (min-height: $breakpoint) {
    @content;
  }
}

@mixin media-below-and-landscape($breakpoint) {
  @media (max-width: ($breakpoint - 1)) and (min-aspect-ratio: 13/9) {
    @content;
  }
}

@mixin fullWindowHeight() {
  height: calc(var(--app-height, 100vh));
}

@mixin flex($direction: row, $justify: flex-start, $align-items: flex-start, $flex-wrap: wrap) {
  display: flex;
  flex-wrap: $flex-wrap;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align-items;
}

@mixin has-mob-footer($bp: variables.$bp-ld) {
  :host-context(body.has-footer) {
    @include media-below($bp) {
      @content;
    }
  }
}

@mixin spread-map($map: ()) {
  @each $key, $value in $map {
    #{$key}: $value;
  }
}

/*
  Returns a CSS variable property specific to themeing.
  Accepts an optional fallback, and optional map.
  - SCSS: color: theme-var($--theme-primary, blue)
  - CSS:  color: var(--theme-primary, blue)
  Throws error if the variable not part of the map
*/
@function theme-var($key, $fallback: null, $map: variables.$theme-map-default-dark) {
  @if not map.has-key($map, $key) {
    @error "key: '#{$key}', is not a key in map: #{$map}";
  }
  @if ($fallback) {
    @return var($key, $fallback);
  } @else {
    @return var($key);
  }
}

@mixin default-mode($context-selector: null) {
  :host-context(html.default-dark) {
    @content;
  }
}

@mixin country-mode-nl($context-selector: null) {
  :host-context(html.holland) {
    @content;
  }
}

@mixin country-mode-uk($context-selector: null) {
  :host-context(html.uk) {
    @content;
  }
}
